@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$color-background: white;
$color-button-selected-background: rgba(#56b4be, 0.05);
$color-button-selected-border: #56b4be;
$color-button-border: #aad9de;
$color-toggle-button: #b72d3f;
$color-text-button: #7f7f7f;
$color-text-title: #7f7f7f;
$color-text-normal: #4c4c4c;
$color-text-accent: #b72d3f;
$color-text-menu-highlight: #aad9de;

body {
    font-family: Poppins;
}


/**
* Used for the mobile view
*/
@mixin notebook-view() {
    @media screen and (max-width: 1536px) {
        @content;
    }
}

/**
*
*/
@mixin mobile-view() {
    @media screen and (max-width: 1179px) {
        @content;
    }
}