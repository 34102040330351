@use "@shopliftr/common-ng" as common;
@use "./base-demo" as demo;

@use "css-fx-layout" as fx;

$theme: common.get-theme();

@include common.all-component-themes($theme);


body {
    font-family: Poppins;
    color: demo.$color-text-normal;
}

h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
}

h2 {
    font-size: 24px;
}

.smart-ads-demo-accent-text {
    color: demo.$color-text-accent;
    font-weight: 550;
}
